<template>
  <tr class="ActiveWalletUsersUser__list-row">
    <td class="ActiveWalletUsersUser__list-cell">{{ user.fullName }}</td>
    <td class="ActiveWalletUsersUser__list-cell">{{ user.username }}</td>
    <td class="ActiveWalletUsersUser__list-cell">{{ user.email }}</td>
    <td class="ActiveWalletUsersUser__list-cell">{{ user.phone }}</td>
    <td class="ActiveWalletUsersUser__list-cell">
      <div
        v-tooltip="{
          content: getBvnDetails,
          html: true,
          loadingContent: 'Please wait...',
          loadingClass: 'content-is-loading',
        }"
        v-if="user.bvn"
        class="ActiveWalletUsersUser__btn"
        @click="copyBvnToClipboard"
      >
        {{ user.bvn }}
      </div>
    </td>
    <td class="ActiveWalletUsersUser__list-cell">
      <router-link
        :to="{ name: 'wallet', params: { id: user.userId } }"
        class="button button-success"
      >
        Details
      </router-link>
    </td>
  </tr>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('wallets');

export default {
  name: 'ActiveWalletUsersUser',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bvnData: null,
    };
  },
  methods: {
    ...mapActions(['fetchBvnDetails']),
    async getBvnDetails() {
      if (!this.bvnData) {
        const { response } = await this.fetchBvnDetails(this.user.bvn);
        if (response) {
          const { firstName, lastName, dateOfBirth } = response;
          this.bvnData = `${firstName} ${lastName}, ${dateOfBirth}`;
        }
      }
      return this.bvnData || 'No BVN data';
    },
    copyBvnToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.bvnData;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$toastr.s('BVN data copied to clipboard');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/wallets/ActiveWalletUsersUser.scss';
</style>
