<template>
  <div class="ActiveWalletUsers Grid-row">
    <div class="Grid-column-12">
      <table class="ActiveWalletUsers__list">
        <thead>
          <tr class="ActiveWalletUsers__list-header">
            <th class="ActiveWalletUsers__list-cell">Full name</th>
            <th class="ActiveWalletUsers__list-cell">Username</th>
            <th class="ActiveWalletUsers__list-cell">Email</th>
            <th class="ActiveWalletUsers__list-cell">Phone number</th>
            <th class="ActiveWalletUsers__list-cell">BVN</th>
            <th class="ActiveWalletUsers__list-cell">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <active-wallet-users-user
            v-for="user in activeWallets"
            :key="user.userId"
            :user="user"
          ></active-wallet-users-user>
          <tr>
            <td colspan="6">
              <infinite-loading
                ref="infiniteLoading"
                @infinite="getActiveWallets"
              >
                <div slot="no-more"></div>
              </infinite-loading>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import ActiveWalletUsersUser from './ActiveWalletUsersUser';

const { mapGetters, mapActions } = createNamespacedHelpers('wallets');

export default {
  name: 'ActiveWalletUsers',
  components: {
    InfiniteLoading,
    ActiveWalletUsersUser,
  },
  computed: {
    ...mapGetters(['activeWallets', 'activeWalletsNextToken']),
  },
  methods: {
    ...mapActions(['fetchActiveWalletUsers']),
    async getActiveWallets(st) {
      const { response } = await this.fetchActiveWalletUsers();
      if (response && response.users && response.users.length) st.loaded();
      if (!this.activeWalletsNextToken) st.complete();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/wallets/ActiveWalletUsers.scss';
</style>
